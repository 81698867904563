<template>
  <div class="templateWeight  template-btn" :style="{ 'padding-top': getTemplatePaddingTop }">

    <Head></Head>
    <div class="animation" v-if="animationLoading && templateData">
      <div class="title title_1 tc" v-html="templateData.languageConfig[$language].mainTitle"></div>
      <div class="type-tabs">
        <div class="type-item" @click="typeChange(item)" :class="{ active: currentType == item }"
          v-for="item in typeList" :key="item">
          {{ item }}
        </div>
      </div>
      <div class="ipt-warp flex" v-show="currentType == 'kg'">
        <div class="ipt-item title_3">
          <van-field type="number" @focus="e => e.target.value == '0' ? e.target.value = '' : ''"
            :style="{ width: iptWidth1 + 'px' }" class="ipt" :class="{ noData: kgValue == 0 }" v-model="kgValue" />
          <div>kg</div>
        </div>
      </div>
      <div class="ipt-warp flex" v-show="currentType == 'lbs'">
        <div class="ipt-item title_3">
          <van-field @focus="e => e.target.value == '0' ? e.target.value = '' : ''" type="number"
            :style="{ width: iptWidth2 + 'px' }" class="ipt" :class="{ noData: lbsValue == 0 }" v-model="lbsValue" />
          <div>lbs</div>
        </div>
      </div>
      <div class="ipt-tip title_3 tip-color tc">
        <span v-show="currentType == 'kg'" class="amimation-height"
          :class="showTip1 && kgValue ? 'tip-visible' : 'hidden'">
          {{ $t.EnterAValueFrom }} {{ minKg }} {{ $t.to }} {{ maxKg }} KG</span>
        <span v-show="currentType == 'lbs'" class="amimation-height"
          :class="showTip2 && lbsValue ? 'tip-visible' : 'hidden'">
          {{ $t.EnterAValueFrom }} {{ minLbs }} {{ $t.to }} {{ maxLbs }} LBS</span>
      </div>

      <div class="amimation-height" :class="heigthTip ? 'weight-visible' : 'hidden'">
        <div class="height-tip " v-if="tipConfig" :style="{ background: tipConfig.background }">
          <div class="flex align-center">
            <img class="icon" :src="require(`@img/${tipConfig.icon}`)" alt="">
            <div class="title text_1">{{ $utils.replaceTextValue($t.YourBmi, bmi) }} <span
                :style="{ color: tipConfig.color }">{{
                  tipConfig.name }}</span></div>
          </div>
          <div class="sub-title text_3">
            {{ tipConfig.text }}
          </div>
        </div>
      </div>
    </div>
    <div class="btn-warp">
      <div @click="validateClick()" :class="{ active: validInputValue() }" class="button text_1"
        v-html="templateData.languageConfig[$language].buttonText">
      </div>
    </div>
  </div>
</template>
<script>
import Head from "./components/head.vue"
import mixinTemplate from "@/mixin";
import webConfig from "@/config/web.config/index";
import { setmMxpanelUserValue } from "@/utils/mixpanel"
export default {
  name: "templateWeight",
  mixins: [mixinTemplate],
  components: {
    Head,
  },
  data() {
    return {
      heigthTip: false,
      iptWidth1: 30,
      iptWidth2: 30,
      showTip1: false,
      showTip2: false,
      typeList: ["lbs", "kg"],
      currentType: "lbs",
      kgValue: 0,
      lbsValue: 0,
      minKg: 30,
      maxKg: 250,
      minLbs: 66,
      maxLbs: 551,
      bmi: 0,
      tipConfig: null,
      isActive: false
    };
  },
  watch: {
    kgValue(newValue, oldValue) {
      this.kgValue = this.inputValueWatch(newValue, oldValue, this.minKg, this.maxKg, 999);
      this.iptWidth1 = this.kgValue.toString().length * 31;
      this.bmi = this.$utils.calculateBMI(this.$store.state.onBoardingRecordInfo[this.$utils.getKey('heightScreen')], this.kgValue);
      this.getBMIConfig();
      this.iptEvent(newValue, 2)

    },
    lbsValue(newValue, oldValue) {
      this.iptEvent(newValue, 1)
      this.lbsValue = this.inputValueWatch(newValue, oldValue, this.minLbs, this.maxLbs, 999);
      this.iptWidth2 = this.lbsValue.toString().length * 31;
      this.bmi = this.$utils.calculateBMI(this.$store.state.onBoardingRecordInfo[this.$utils.getKey('heightScreen')], this.$utils.lbsToKg(this.lbsValue));
      this.getBMIConfig();
    }
  },
  mounted() {
    let key = this.$utils.getKey(this.templateData.screenName);
    if (this.$store.state.onBoardingRecordInfo[key]) {
      this.currentType = (this.$store.state.onBoardingRecordInfo[this.$utils.getKey('weightUnit')] == webConfig.weightKgUnitNumber ? 'kg' : 'lbs');
      //存储为kg
      if (webConfig.weightType == 0) {
        this.kgValue = this.$store.state.onBoardingRecordInfo[key];
        this.lbsValue = this.$utils.kgToLbs(this.$store.state.onBoardingRecordInfo[key], this.minLbs, this.maxLbs);
      } else {
        this.lbsValue = this.$store.state.onBoardingRecordInfo[key];
        this.kgValue = this.$utils.lbsToKg(this.lbsValue, this.minKg, this.maxKg);
      }
    }
  },
  methods: {
    // 监听输入框value变化
    inputValueWatch(newValue, oldValue, min, max, maxNumber = 9) {
      if (newValue == '') return 0
      if (oldValue == 0) newValue = newValue * 1
      let res = 0
      let str = (newValue + '').split('.');
      if ((newValue + '').includes('.')) {
        if (str && str[1] && str[1].length > 1) {
          res = oldValue
        }
        else {
          res = newValue
        }
      } else {
        res = Number(newValue) > maxNumber ? oldValue : newValue
      }
      return res
    },
    //输入监听
    iptEvent(value, type) {
      switch (type) {
        case 2: //kg
          this.showTip1 = !this.validInputValue()
          break;
        case 1: //lbs
          this.showTip2 = !this.validInputValue()
          break;
      }
    },
    // bmi描述
    getBMIConfig() {
      this.heigthTip = true;
      if (this.bmi) {
        if (this.bmi <= this.$utils.initUnderweight) {
          this.tipConfig = {
            name: this.$t.underweight,
            icon: 'bmi01.png',
            background: '#EED6D6',
            color: '#F24822',
            text: this.$t.weightBmiDescription1
          }
        } else if (this.$utils.initUnderweight < this.bmi && this.bmi <= this.$utils.initNormal) {
          this.tipConfig = {
            name: this.$t.normal,
            icon: 'bmi02.png',
            background: '#CFDED1',
            color: '#34C759',
            text: this.$t.weightBmiDescription2
          }
        }
        else if (this.$utils.initNormal < this.bmi && this.bmi <= this.$utils.initOverweight) {
          this.tipConfig = {
            name: this.$t.overweight,
            icon: 'bmi03.png',
            background: '#F3EBD4',
            color: '#FFA800',
            text: this.$t.weightBmiDescription3
          }
        }
        else if (this.bmi > this.$utils.initOverweight) {
          this.tipConfig = {
            name: this.$t.obese,
            icon: 'bmi01.png',
            background: '#EED6D6',
            color: '#F24822',
            text: this.$t.weightBmiDescription4
          }
        }
      } else {
        this.heigthTip = false;
      }
    },
    typeChange(item) {
      this.currentType = item;
      if (this.currentType == "kg") {
        this.showTip2 = false;
        this.kgValue = this.$utils.lbsToKg(this.lbsValue, this.minKg, this.maxKg);
      } else {
        this.showTip1 = false;
        this.lbsValue = this.$utils.kgToLbs(this.kgValue, this.minLbs, this.maxLbs);

      }
    },
    validInputValue() {
      let bool = false;
      if (this.currentType == 'lbs') {
        bool = (this.lbsValue && this.lbsValue >= this.minLbs && this.lbsValue <= this.maxLbs);
      } else {
        bool = (this.kgValue && this.kgValue >= this.minKg && this.kgValue <= this.maxKg);
      }
      return bool
    },
    validateClick(isNext = true) {
      if (this.validInputValue()) {
        //输入是否符合规则
        if (this.currentType == "lbs") {
          this.kgValue = this.$utils.lbsToKg(this.lbsValue);
        } else {
          this.lbsValue = this.$utils.kgToLbs(this.kgValue);
        }

        this.updateOnBoardingRecord({
          key: 'weightUnit',
          value: this.currentType == 'kg' ? webConfig.weightKgUnitNumber : (webConfig.weightKgUnitNumber == 1 ? 0 : 1)
        })
        setmMxpanelUserValue('user_bmi', this.bmi)
        this.selectOption(webConfig.weightType == 0 ? this.kgValue * 1 : this.lbsValue * 1, isNext);
      }
    }
  },
  beforeDestroy() {
    this.validateClick(false);// 离开页面存储答案
  }
};
</script>
<style scoped lang="scss">
.templateWeight {
  .type-tabs {
    margin: 50px auto;
    padding: 2px;
    display: flex;
    width: 220px;
    height: 45px;
    background-color: var(--theme_03);
    border-radius: 8px;

    .type-item {
      width: 50%;
      font-size: 14px;
      font-weight: 600;
      line-height: 41px;
      text-align: center;
      color: var(--theme_01);
      border-radius: 8px;
      cursor: pointer;
    }

    .active {
      color: var(--theme_03);
      background-color: var(--mainColor);
    }
  }

  .ipt-warp {
    margin: 0 auto;
    margin-top: 80px;
    text-align: center;
    justify-content: center;

    .ipt-item {
      display: flex;
      align-items: flex-end;
      font-weight: 700;

      color: var(--theme_01);

      div {
        font-size: 14px;
        font-weight: 600;
      }

      .ipt {
        background: none;
        min-width: 35px;
        margin-right: 5px;

        /deep/.van-field__control {
          display: inline;
          width: 100%;
          height: 55px;
          font-size: 48px;
          min-width: 30px;
          line-height: 52px;
          font-weight: 700;
          text-align: center;
          color: var(--theme_01);
        }
      }

      .noData {
        /deep/.van-field__control {
          color: var(--theme_02);
        }
      }


    }
  }

  .ipt-tip {
    width: 100%;
    margin: 0 auto;
    margin-top: 5px;
    height: 18px;
  }



  .height-tip {
    margin: 20px auto;
    padding: 12px 16px;
    width: 100%;
    border-radius: 12px;

    .icon {
      margin-right: 5px;
      height: 24px;
    }

    .title {
      flex: 1;
      position: relative;
      margin-top: 0;
    }
  }

}

@media (max-width: 576px) {
  .templateWeight .height-tip .title ::after {
    top: 0;
  }
}
</style>
