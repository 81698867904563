<template>
  <div class="templateSummary   template-btn" :style="{ 'padding-top': getTemplatePaddingTop }">

    <Head></Head>
    <div class="animation" v-if="animationLoading && templateData">
      <div class="heading heading_1 tc" v-html="templateData.languageConfig[$language].mainTitle"></div>
      <div class="chart-warp">
        <bmi-chart :bmi="bmi" :bmiName="bmiName" :bmiIndex="bmiIndex" :progressNumber="progressNumber"></bmi-chart>
      </div>
      <div class="content flex">
        <div class="body-info flex-auto">
          <template v-for="(item, index) in webConfig.summaryBodyInfoList">
            <div class="body-item" :style="{ 'animation-delay': (index * 0.5) + 0.5 + 's' }" :key="item.type"
              v-if="$store.state.onBoardingRecordInfo[item.value + 'Option']">
              <div class="body-type text_3">{{ $t[item.type] }} <span v-if="item.type == 'BodyType'">（{{ $t.BasedOnBMI
                  }}） </span></div>
              <div class="body-text flex align-center ">
                <img :src="require(`@img/${item.type}.png`)" width="24" alt="">
                <div class="title_3 flex-auto">{{ item.type == 'BodyType' ? $t[bmiName] :
                  $store.state.onBoardingRecordInfo[item.value + 'Option'] }}</div>
              </div>
            </div>
          </template>

        </div>
        <img class="img" :src="require(`@img/template/${bmiName}.png`)" alt="" />
      </div>
      <template v-for="item in tipList">
        <div class="tips" :key="item.name" v-if="item.name == bmiName" :style="{ background: item.background }">
          <div class="flex align-center flex-warp">
            <van-icon style="font-weight: 600;" name="warning-o" :color="item.iconColor" size="24" />
            <div class="title title_3 flex-auto">{{ item.title }}</div>
          </div>
          <div class="sub-title text_4">
            {{ item.text }}
          </div>
        </div>
      </template>
    </div>
    <div class="btn-warp">
      <div @click="contimueClick()" class="button active text_1"
        v-html="templateData.languageConfig[$language].buttonText">
      </div>
    </div>
  </div>
</template>
<script>
import Head from "./components/head.vue"
import bmiChart from "@/components/echarts/bmiChart";
import mixinTemplate from "@/mixin";
import webConfig from "@/config/web.config/index"
export default {
  name: "templateSummary",
  mixins: [mixinTemplate],
  components: {
    Head,
    bmiChart
  },
  created() {
    this.webConfig = webConfig;
    let height = this.$store.state.onBoardingRecordInfo[this.$utils.getKey('heightScreen')];
    let weight = this.$store.state.onBoardingRecordInfo[this.$utils.getKey('currentWeightScreen')];
    weight = webConfig.weightType == 0 ? weight : this.$utils.lbsToKg(weight);
    this.bmi = this.$utils.calculateBMI(height, weight);
    this.getProgress()
  },
  data() {
    return {
      webConfig: null,
      bmi: 0,
      bmiIndex: 0,
      progressNumber: 0,
      bmiName: '',
      ageValue: "",

      tipList: [
        {
          name: 'underweight',
          title: this.$t.summaryTipsTitle1,
          iconColor: '#4081E2',
          text: this.$t.summaryTipsText1,
          background: '#D4E4F3'
        },
        {
          name: 'normal',
          title: this.$t.summaryTipsTitle2,
          iconColor: '#17BD31',
          text: this.$t.summaryTipsText2,
          background: '#D5E2D7'
        },
        {
          name: 'overweight',
          title: this.$t.summaryTipsTitle3,
          iconColor: '#C6981D',
          text: this.$t.summaryTipsText3,
          background: '#F3EDDD'
        },
        {
          name: 'obese',
          title: this.$t.summaryTipsTitle4,
          iconColor: '#D51E1E',
          text: this.$t.summaryTipsText4,
          background: '#EED6D6'
        }
      ]
    };
  },
  methods: {
    // 获取进度
    getProgress() {
      let progressNumber = 0;
      if (this.bmi <= 15) {
        this.bmiName = "underweight";
      } else if (this.bmi > 40) {
        this.bmiName = "obese";
        progressNumber = 100;
        this.bmiIndex = 3
      }
      else {
        switch (true) {
          case this.bmi <= 18.5:
            progressNumber = 25 / (18.5 - 15) * (this.bmi - 15) + 25 * 0;
            this.bmiName = "underweight";
            this.bmiIndex = 0;
            break;
          case this.bmi > 18.5 && this.bmi <= 25:
            progressNumber = 25 / (25 - 18.5) * (this.bmi - 18.5) + 25 * 1;
            this.bmiName = "normal"
            this.bmiIndex = 1;
            break;
          case this.bmi > 25 && this.bmi <= 30:
            progressNumber = 25 / (30 - 25) * (this.bmi - 25) + 25 * 2;
            this.bmiName = "overweight"
            this.bmiIndex = 2
            break;
          case this.bmi > 30 && this.bmi <= 40:
            progressNumber = 25 / (40 - 30) * (this.bmi - 30) + 25 * 3;
            this.bmiName = "obese"
            this.bmiIndex = 3
            break;
        }
      }
      this.progressNumber = progressNumber
    }
  }
};
</script>
<style scoped lang="scss">
.templateSummary {
  .chart-warp {
    margin: 10px 0;
    width: 100%;
    height: 180px;
    background-color: var(--theme_03);
    border-radius: 8px;
  }

  .content {
    .img {
      height: 230px;
    }

    .body-info {
      padding-top: 10px;

      @keyframes animation-translate-body-items {
        0% {

          opacity: 0;
          transform: translateY(20px);
        }

        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .body-item {
        margin-bottom: 10px;
        opacity: 0;
        transform: translateY(20px);
        animation-name: animation-translate-body-items;
        /* 使用定义的动画 */
        animation-duration: .5s;
        /* 动画持续时间 */
        animation-timing-function: ease-in-out;
        /* 动画速度曲线 */
        animation-fill-mode: forwards;

        /* 动画结束后保持最后一个关键帧的状态 */
        .body-type {
          color: var(--theme_02);
        }
      }
    }

    .text_2 {
      color: var(--theme_02);
    }

    .body-text {


      .title_3 {
        padding-top: 1px;
        margin-left: 8px;
        color: var(--theme_01);
      }
    }
  }

  .tips {
    margin-bottom: 20px;
    padding: 12px 16px;
    width: 100%;
    background: rgba(213, 226, 215, 1);
    border-radius: 16px;

    .sub-title {
      color: var(--theme_01);
    }

    .title {
      margin-top: 0;
      margin-left: 5px;
      position: relative;
    }
  }
}

@media (max-width: 576px) {
  .templateSummary {
    .chart-warp {
      height: 160px;
      overflow: hidden;
    }

    .content {
      .img {
        height: 200px;
      }
    }
  }
}
</style>
