<template>
  <div class="templateHeight  template-btn" :style="{ 'padding-top': getTemplatePaddingTop }">

    <Head></Head>
    <div class="animation" v-if="animationLoading && templateData">
      <div class="title title_1 tc" v-html="templateData.languageConfig[$language].mainTitle"></div>
      <div class="type-tabs">
        <div class="type-item" @click="typeChange(item)" :class="{ active: currentType == item }"
          v-for="item in typeList" :key="item">
          {{ item }}
        </div>
      </div>
      <div class="ipt-warp flex" v-show="currentType == 'in'">
        <div class="ipt-item">
          <van-field type="number" class="ipt" @focus="(e) => (e.target.value == '0' ? (e.target.value = '') : '')"
            :style="{ width: iptWidth2 + 'px' }" :class="{ noData: ftValue == 0 }" v-model="ftValue" />
          <span>ft</span>
        </div>
        <div class="ipt-item">
          <van-field ref="iptInRef" type="number" @focus="(e) => (e.target.value == '0' ? (e.target.value = '') : '')"
            class="ipt" :style="{ width: iptWidth3 + 'px' }" :class="{ noData: inValue == 0 }" v-model="inValue" />
          <span>in</span>
        </div>
      </div>
      <div class="ipt-warp flex" v-show="currentType == 'cm'">
        <div class="ipt-item">
          <van-field type="number" class="ipt" :style="{ width: iptWidth + 'px' }"
            @focus="(e) => (e.target.value == '0' ? (e.target.value = '') : '')" :class="{ noData: cmValue == 0 }"
            v-model="cmValue" />
          <span>cm</span>
        </div>
      </div>
      <div class="ipt-tip title_3 tip-color tc">
        <span class="amimation-height" v-show="currentType == 'in'"
          :class="showTip1 && (ftValue || inValue) ? 'tip-visible' : 'hidden'">
          {{ $t.EnterAValueFrom }} {{ minFt }} {{ $t.to }} {{ maxFt }} ft {{ maxIn }} ft
        </span>
        <span v-show="currentType == 'cm'" class="amimation-height"
          :class="showTip2 && cmValue ? 'tip-visible' : 'hidden'">
          {{ $t.EnterAValueFrom }} {{ minCm }} {{ $t.to }} {{ maxCm }} cm</span>
      </div>
      <div class="height-tip">
        <div class="title text_1 flex align-center">
          <img class="img" src="@img/ruler.png" width="24" alt="" />
          <div class="flex-auto">{{ $t.Calculating }}</div>
        </div>
        <div class="sub-title text_3">
          {{ $t.BMIIsCommonly }}
        </div>
      </div>
    </div>
    <div class="btn-warp">
      <div @click="validateClick()" class="button text_1" :class="{ active: validInputValue() }"
        v-html="templateData.languageConfig[$language].buttonText"></div>
    </div>
  </div>
</template>
<script>
import Head from "./components/head.vue"
import mixinTemplate from "@/mixin";
import webConfig from "@/config/web.config/index";
export default {
  name: "templateHeight",
  mixins: [mixinTemplate],
  components: {
    Head,
  },
  data() {
    return {
      iptWidth: 30,
      iptWidth2: 30,
      iptWidth3: 30,
      showTip1: false,
      showTip2: false,
      typeList: ["in", "cm"],
      currentType: "in",
      ftValue: 0,
      inValue: 0,
      cmValue: 0,
      maxIn: 11,
      minIn: 0,
      minValue: 0,
      minFt: 4,
      maxFt: 7,
      minCm: 100,
      maxCm: 250,
      isActive: false,
    };
  },
  mounted() {
    let key = this.$utils.getKey(this.templateData.screenName);
    if (this.$store.state.onBoardingRecordInfo[key]) {
      this.currentType = (this.$store.state.onBoardingRecordInfo[this.$utils.getKey("heightUnit")] == webConfig.heightCmUnitNumber ? 'cm' : 'in')
      this.cmValue = this.$store.state.onBoardingRecordInfo[key];
      this.cmToFtIn();
    }
  },
  watch: {
    ftValue(newValue, oldValue) {
      this.ftValue = this.$utils.inputValueWatch(newValue, oldValue);
      this.iptEvent(newValue, 1)
      this.iptWidth2 = this.ftValue.toString().length * 30;
    },
    inValue(newValue, oldValue) {
      this.inValue = this.$utils.inputValueWatch(newValue, oldValue, 11);
      this.iptEvent(newValue, 3)
      this.iptWidth3 = this.inValue.toString().length * 30;
    },
    cmValue(newValue, oldValue) {
      this.iptEvent(newValue, 2)
      this.cmValue = this.$utils.inputValueWatch(newValue, oldValue, 999);
      this.iptWidth = this.cmValue.toString().length * 30;
    },
  },
  methods: {

    // ft/in 转cm
    ftInToCm() {
      if (!this.ftValue) this.ftValue = 0;
      if (!this.inValue) this.inValue = 0;
      let res = Math.round(
        this.ftValue * this.$utils.initFt + this.inValue * this.$utils.initIn
      ); //四舍五入
      let cmValue = res ? res : 0;
      if (cmValue && cmValue < this.minCm) {
        cmValue = this.minCm
      }
      if (cmValue && cmValue > this.maxCm) {
        cmValue = this.maxCm
      }
      this.cmValue = cmValue
    },
    // cm转ft/in
    cmToFtIn() {
      let ftValue = 0;
      let inValue = 0;
      if (this.cmValue) {
        ftValue = parseInt(this.cmValue / this.$utils.initFt);
        inValue = Math.round(
          (this.cmValue - ftValue * this.$utils.initFt) / this.$utils.initIn
        ); //四舍五入
      }
      if (inValue && inValue >= 12) {
        ftValue += 1
        inValue = 0
      }
      if (ftValue && ftValue < this.minFt) {
        ftValue = this.minFt
        inValue = this.minValue
      }
      if (ftValue && ftValue > this.maxFt) {
        ftValue = this.maxFt
        inValue = this.maxIn
      }
      this.ftValue = ftValue;
      this.inValue = inValue;

    },
    typeChange(item) {
      this.currentType = item;
      if (this.currentType == "in") {
        this.cmToFtIn();
      } else {
        this.ftInToCm();
      }
    },
    validInputValue() {
      let bool = false;
      if (this.currentType == 'in') {
        bool = (this.ftValue && this.ftValue >= this.minFt && this.ftValue <= this.maxFt);
      } else {
        bool = (this.cmValue && this.cmValue >= this.minCm && this.cmValue <= this.maxCm);
      }
      return bool
    },
    //验证
    validateClick(isNext = true) {
      if (this.validInputValue()) {
        //输入是否符合规则
        if (this.currentType == "in") {
          this.ftInToCm();
        }
        this.updateOnBoardingRecord({
          key: this.$utils.getKey("heightUnit"),
          value: this.currentType == "cm" ? webConfig.heightCmUnitNumber : (webConfig.heightCmUnitNumber == 1 ? 0 : 1),
        });
        this.selectOption(this.cmValue * 1, isNext);
      }

    },
    //输入监听
    iptEvent(value, type) {
      switch (type) {
        case 1: //ft
          this.showTip1 = !this.validInputValue()
          break;
        case 2: //cm
          this.showTip2 = !this.validInputValue()
          break;
        case 3:
          this.showTip1 = this.ftValue ? ((this.ftValue < this.minFt || this.ftValue > this.maxFt) ? true : false) : true;
          break;
      }
    },
  },
  beforeDestroy() {
    this.validateClick(false); // 离开页面存储答案
  },
};
</script>
<style scoped lang="scss">
.templateHeight {
  .type-tabs {
    margin: 0 auto;
    margin-top: 26px;
    padding: 2px;
    display: flex;
    width: 132px;
    height: 45px;
    background-color: var(--theme_03);
    border-radius: 8px;

    .type-item {
      width: 132px;
      font-size: 14px;
      font-weight: 600;
      line-height: 41px;
      text-align: center;
      color: var(--theme_01);
      border-radius: 8px;
      cursor: pointer;
    }

    .active {
      color: var(--theme_03);
      background-color: var(--mainColor);
    }
  }

  .ipt-warp {
    margin: 0 auto;
    margin-top: 80px;
    text-align: center;
    justify-content: center;

    .ipt-item {
      display: flex;
      align-items: flex-end;
      font-weight: 700;

      color: var(--theme_01);

      div {
        font-size: 14px;
        font-weight: 600;
      }

      .ipt {
        background: none;
        min-width: 35px;
        margin-right: 5px;

        /deep/.van-field__control {
          display: inline;
          width: 100%;
          height: 55px;
          font-size: 48px;
          line-height: 52px;
          font-weight: 700;
          text-align: center;
          color: var(--theme_01);
        }
      }

      .noData {
        /deep/.van-field__control {
          color: var(--theme_02);
        }
      }
    }
  }

  .ipt-tip {
    width: 100%;
    margin: 0 auto;
    margin-top: 5px;
    height: 18px;
  }

  .height-tip {
    margin: 20px auto;
    padding: 12px 16px;
    width: 100%;
    max-width: 550px;
    border-radius: 12px;
    background: #e0e0ea;

    .title {
      font-weight: 600;

      .img {
        margin-top: -5px;
        margin-right: 8px;
      }

      position: relative;
      margin-top: 0;
    }
  }
}

@media (max-width: 576px) {
  .templateHeight .height-tip .title ::after {
    top: 0;
  }
}
</style>
