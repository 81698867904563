<template>
  <div class="templateSider " :style="{ 'padding-top': getTemplatePaddingTop }">

    <Head></Head>
    <div class="animation" v-if="animationLoading && templateData">
      <div class="heading_1 title tc"
        v-html="$utils.redirectUrlKeyword(templateData.languageConfig[$language].mainTitle)"></div>
      <div class="title_2 sub-title tc" v-html="templateData.languageConfig[$language].subTitle"></div>
      <div class="text_1 tc" v-html="templateData.languageConfig[$language].tips"></div>
      <van-circle v-model="currentRate" :rate="50" size="120px" text="大小定制" layer-color="none" />
      <div class="slider-container">
        <!-- 显示图片 -->
        <div class="image-display">
          <img :src="images[imgIndex]" alt="Selected image" class="displayed-image" />
        </div>
        <div class="range-container">

          <input type="range" min="0" max="100" step="1" v-model="currentValue" @input="handleInput"
            @change="handleChange">
          <div class="range-ticks">
            <div v-for=" item in points" :key="item" @click="pointClick(item)"></div>
          </div>
        </div>
        <!-- 标签 -->
        <div class="range-sacle text_2">
          <span>Inactive</span>
          <span>Very active</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixinTemplate from "@/mixin/index";
import Head from "./components/head.vue"
import { time } from "echarts";

export default {
  name: "templateSider",
  mixins: [mixinTemplate],
  components: {
    Head,
  },
  data() {
    return {
      currentRate: 22,
      imgIndex: 0,
      value: 0, // 当前点位置
      currentValue: 0, // 当前滑块值，用于检测滑动位置
      images: [
        require('@img/template/focus-image-1.png'), // 根据路径更新图片
        require('@img/template/focus-image-2.png'), // 根据路径更新图片
        require('@img/template/focus-image-3.png'), // 根据路径更新图片
        require('@img/template/focus-image-4.png'), // 根据路径更新图片

      ],
      points: [0, 33, 66, 100], // 定义4个主要点
    }
  },
  methods: {
    getImgIndex() {
      this.imgIndex = this.points.findIndex(item => item == this.value)
    },    // 主要点点击
    pointClick(item) {
      this.value = item;
      this.currentValue = item;
      this.getImgIndex()
    },

    handleInput() {
      const closestPoint = this.getClosestPoint(this.currentValue);
      const diff = this.currentValue - closestPoint;
      // 如果滑块超过最近点的一半，移动到下一个点
      if (Math.abs(diff) >= 16.5) {
        this.value = diff > 0 ? this.points[this.points.indexOf(closestPoint) + 1] : this.points[this.points.indexOf(closestPoint) - 1];
      } else {
        this.value = closestPoint;
      }


      this.getImgIndex()
    },
    handleChange() {
      // 更新滑块位置以匹配新位置
      this.currentValue = this.value;
    },
    getClosestPoint(value) {
      return this.points.reduce((prev, curr) => {
        return Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev;
      });
    },
  }


};
</script>
<style scoped lang="scss">
.templateSider {
  /deep/.van-slider__button-wrapper {
    z-index: 9;
  }

  /deep/ .van-slider__button {
    background-color: var(--bgColor) !important;

  }

  /* 容器样式 */
  .range-container {
    position: relative;
    width: 100%;
  }

  /* 自定义滑块样式 */
  input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 8px;
    /* background: #ddd; */
    background: none;
    outline: none;
    border-radius: 4px;
    margin: 0;
    position: relative;
    z-index: 2;
    /* 滑块的层级高于刻度标记 */
  }

  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    /* background: #007bff; */
    background: none;
    border-radius: 4px;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    background: var(--bgColor);
    border: 2px solid var(--mainColor);
    border-radius: 50%;
    cursor: pointer;
    margin-top: 8px;
    position: relative;
    z-index: 3;
    /* 手柄的层级更高，确保覆盖刻度标记 */
  }

  /* 刻度线容器 */
  .range-ticks {
    padding: 0 5px;
    position: absolute;
    /* 位置调整为与手柄底部对齐 */
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    z-index: 1;
    /* 刻度标记的层级低于滑块 */
    background: #f2eaec;
    border-radius: 10px;
  }

  /* 刻度标记 */
  .range-ticks div {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #bea4ab;
    position: relative;
    top: 7px;
  }

  .range-sacle {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--theme_02);
  }
}
</style>