<template>
    <div class="templateSayhi  template-btn" :style="{ 'padding-top': getTemplatePaddingTop }">

        <Head></Head>
        <div class="animation" v-if="animationLoading && templateData">
            <div class="title_2 ">{{ $t.YourWeight }}</div>
            <div class="img-warp">
                <div class="mark"></div>
                <img class="img" v-if="weight - goalWeight > 0" src="@img/template/weight1.png" width="100%" alt="">
                <img class="img" v-else-if="weight == goalWeight" src="@img/template/weight2.png" width="100%" alt="">
                <img class="img" v-else src="@img/template/weight3.png" width="100%" alt="">
            </div>
            <div class="text_4 theme_02 ">{{ $t.ThisChart }}</div>
            <div class="title title_1 theme_01 tc "
                v-html="$utils.redirectUrlKeyword(templateData.languageConfig[$language].mainTitle)"> </div>

        </div>
        <div class="btn-warp">
            <div @click="contimueClick()" class="button active text_1"
                v-html="templateData.languageConfig[$language].buttonText">
            </div>
        </div>
    </div>
</template>
</template>
<script>

import Head from "./components/head.vue"
import mixinTemplate from "@/mixin";

export default {
    name: "templateSayhi",
    mixins: [mixinTemplate],
    components: {
        Head
    },
    data() {
        return {
            weight: 0,
            goalWeight: 0,
        }
    },
    mounted() {
        this.weight = this.$store.state.onBoardingRecordInfo[this.$utils.getKey('currentWeightScreen')];
        this.goalWeight = this.$store.state.onBoardingRecordInfo[this.$utils.getKey('targetWeightScreen')];
        let uid = this.$store.state.onBoardingRecordInfo[this.$utils.getKey('uid')];
        //保底避免未存入uid的情况
        uid = uid ? uid : window.sessionStorage.getItem('uid')
        this.$store.commit("updateOnBoardingRecord", {
            key: this.$utils.getKey('uid'),
            value: uid,
        });
    }
}
</script>
<style scoped lang="scss">
.templateSayhi {
    max-width: 500px;

    .title_2 {
        margin-top: 10px;
    }

    .img-warp {
        position: relative;
        margin: 10px 0;
        height: 100%;
        overflow: hidden;
    }

    .img {
        width: 100%;
    }

    .mark {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        background: var(--bgColor);
        z-index: 9;
        transform: translate(100%, 0);
        animation: slide-in 1.3s linear;
    }

    @keyframes slide-in {
        from {
            transform: translate(0, 0);
        }

        to {
            transform: translate(100%, 0);
        }
    }
}
</style>